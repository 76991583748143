.deleteButton {
    background-color: #f50057!important;
    color: white!important;
    padding: 5px 7px!important;
    border-radius: 13px!important;
}


.cancelButton {
    border: 1px solid #908c8c!important;
    padding: 5px 7px!important;
    border-radius: 13px!important;
}


.dialogContainer>.MuiDialog-container>.MuiDialog-paper {
    border-radius: 12px;
    min-width: 30rem;
}

.dialogContainer>.MuiDialog-container>.MuiDialog-paper>.MuiDialogContent-root{
    padding: 0 !important;
}
.headerDialog>.MuiDialog-container>.MuiDialog-paper {
    border-radius: 12px;
    min-width: 25rem;
    height: max-content;
    background-color: #f5f5f5;
}
.headerDialog>.MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0) !important;
}

.headerDialog>.MuiDialog-container>.MuiDialog-paper>.MuiDialogContent-root{
    padding: 0 !important;
}
.headerDialog>.MuiDialog-container{
    position: absolute;
    right: 0rem;
    display: block;
    top: 2rem;
}

.dialogTitle {
    border-bottom: 1px solid;
    font-size: 1.5rem;
    text-align: center ;
}

.dialogContent {
    padding: 2rem;
    color: black;
    font-size: 1.1rem !important;

}


