.MuiTableRow-root:hover {
  background-color: #f7f7f7;
}

.MuiCard-root {
  /* background-image: url(../assests/images/kneo-bg1.png); */
  overflow: auto !important;
  width: -webkit-fill-available;
  height: calc(100vh - 70px);
  background-color: #ffffff !important;
  flex: 1;
  margin: 0;
}
.btn {
  margin-top: 20px;
  background-color: #0071bc !important;
  color: white !important;
  padding: 10px 20px !important;
  border-radius: 15px !important;
  transition: background-color 0.3s;
  margin-top: 1rem !important;
  border: 0;
}
/* .MuiIconButton-root {
  margin-top: 0!important;
} */

.btn:hover {
  background-color: #4ca5e0 !important;
  border-radius: 2rem !important;
}

.itemSpace {
  display: flex;
  justify-content: space-between;
}
.itemCenter {
  display: flex;
  justify-content: center;
}
.itemSpaceEvenly {
  display: flex;
  justify-content: space-evenly;
}

.itemEnd {
  display: flex;
  justify-content: end;
}
.itemStart {
  display: flex;
  justify-content: start;
}
.m1 {
  margin: 1rem !important;
}
.m2 {
  margin: 2rem;
}
.mt-0 {
  margin-top: 0rem !important;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mb-15 {
  margin-bottom: 1.3rem !important;
}
.mr-1 {
  margin-right: 1rem !important;
}
.mr-2 {
  margin-right: 2rem !important;
}
.ml-1 {
  margin-left: 1rem !important;
}
.ml-2 {
  margin-left: 2rem !important;
}
.ml-3 {
  margin-left: 3rem !important;
}
.m0 {
  margin: 0 !important;
}
.mb0 {
  margin-bottom: 0 !important;
}
.mb-05 {
  margin-bottom: 0.5rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}
.mt-3 {
  margin-top: 3rem !important;
}
.p0 {
  padding: 0rem !important;
}
.pt-1 {
  padding-top: 1rem;
}
.pl-2 {
  padding-left: 2rem;
}
.pl-1 {
  padding-left: 1rem;
}
.pt-2 {
  padding-top: 2.5rem;
}
.pt-3 {
  padding-top: 3.7rem;
}
.classFont {
  font-size: 1.3rem;
}
.btnWidth {
  width: fit-content;
  margin-left: 1rem !important;
}
.gradientButtonPink {
  background: linear-gradient(to right, #f04b93 0%, #866eb3 100%) !important;
  color: #ffffff !important;
}

.gradientButtonGray {
  background: linear-gradient(to right, #b9b9bb 0%, #b9b9bb 100%) !important;
  color: #0a0909 !important;
}
.btnHeight {
  line-height: 1.3 !important;
}

.gradientButtonBlue {
  background: linear-gradient(to right, #22b6ba 0%, #0071bc 100%) !important;
  color: #ffffff !important;
}
.gradientButtonOrange {
  background: linear-gradient(to right, #f15a24 0%, #f7931e 100%) !important;
  color: #ffffff !important;
}

.gradientButtonCancel:hover {
  background-color: #ffffff !important;
}

.gradientButtonSave {
  background-color: #ffffff !important;
  border: 1px solid rgba(3, 154, 48, 0.954) !important;
  color: #079c31 !important;
}
.gradientButtonGreen {
  background: linear-gradient(to right, #aacb5f 0%, #aacb5f 100%) !important;
  color: #ffffff !important;
}

.f1 {
  flex: 1;
}

.pageBreadCrumb {
  padding: 1rem 3rem;
  /* //font-weight: bold; */
  font-size: 16px;
}
.pageBreadCrumb a {
  color: #0e72b5;
}
.MuiCheckbox-colorSecondary.Mui-checked {
  color: #aacb5f !important;
}

.MuiTable {
  margin: 1.5rem;
}

.btn-action {
  color: #838383;
  display: inline-flex;
  flex-direction: column;
  margin: 0.3rem 0.6rem 0rem;
  font-size: 0.5rem;
  width: min-content;
}

.btn-icon {
  background-color: #f04b93;
  color: #ffffff;
  height: 2rem;
  line-height: 1em;
  width: 2rem;
  padding: 0.5rem;
  clip-path: circle(50% at center);
}

.btn-icon-info {
  color: #ffffff;
  height: 1.2rem;
  line-height: 1em;
  width: 1.2rem;
  padding: 0.3rem;
  clip-path: circle(50% at center);
}
.btn-vertical-align {
  vertical-align: middle;
}
.btn-icon-play {
  background-color: #f04b93;
  color: #ffffff;
  height: 4rem;
  line-height: 1em;
  width: 4rem;
  padding: 0.5rem;
  clip-path: circle(50% at center);
}

.btn-orange-casablanca {
  background-color: #f8a84b;
}
.btn-purple-wisteria {
  background-color: #866eb3;
}
.btn-assess-wisteria {
  background-color: #22b6ba;
}

.btn-green-wisteria {
  background-color: #28a745;
  padding: 1rem;
}
.btn-blue-wisteria {
  background-color: #338dc9;
}
.btn-blue-java {
  background-color: #22b6ba;
}
.btnClicked {
  box-shadow: 4px 3px 4px 3px rgba(0, 0, 0, 0.4);
}

.accessReport > .MuiTableHead-root > .MuiTableRow-root > .MuiTableCell-root {
  border: 1px solid rgb(6 1 1);
  padding: 5px;
}

.accessReport > .MuiTableBody-root > .MuiTableRow-root > .MuiTableCell-root {
  border: 1px solid rgb(6 1 1);
  padding: 5px;
}

.select-container {
  width: 22rem; /* Adjust the width as per your requirements */
}
.dimestionOption {
  width: 600px;
}
.dimestionOption > ul {
  display: flex;
  flex-direction: column;
  max-height: 200px; /* Adjust the max height as per your requirements */
  overflow-y: auto;
  display: table-caption;
}

.dimestionOption > ul > li {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.MuiList-root {
  display: table-caption !important;
}

/* .MuiTableSortLabel-icon {
  opacity: 1!important;
  transition: opacity 0.3s!important;
}

.MuiTableSortLabel-icon:hover {
  opacity: 1!important;
} */
.MuiList-root {
  display: grid !important;
}
.leftGridCard {
  flex: 1;
  margin-left: 2% !important;
}
.rightGridCard {
  flex: 1;
  margin-right: 2% !important;
}
.arrowButton {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}
.statusInactive {
  display: inline-block;
  vertical-align: text-bottom;
  height: 16px;
  width: 16px;
  background-color: #838383;
  border-radius: 3px;
  background-color: #f7931e;
}
.statusActive {
  display: inline-block;
  vertical-align: text-bottom;
  height: 16px;
  width: 16px;
  background-color: #838383;
  border-radius: 3px;
  background-color: #aacb5f;
}
.inputStudentLogin {
}

/* learnosity button styles*/

.lrn_ratingButton {
  width: 15rem !important;
  height: 10rem !important;
}
.barTopMargin {
  margin-top: 1.8rem !important;
}

.MuiDialog-paper {
  width: 20%;
  float: right;
}
.font600 {
  font-weight: 600;
}

.lrn_ratingInfo_icon_wrap {
  display: none !important;
}

.labelReqField {
  color: #ff0000;
  font-size: 2rem;
  vertical-align: middle;
  font-weight: bold;
}

.lrn.lrn-assess .lrn-right-region .right-wrapper .menu-mask {
  padding: 5px;
}

.top-right-wrapper > .lrn_btn {
  margin-right: 25px !important;
}

.stickyHeader {
  position: sticky;
  top: 0px;
  z-index: 1;
  background-color: #f2f2f2;
}

.MuiTableCell-head {
  /* text-align: center!important; */
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  color: #322e2e !important;
}

.lineAny {
  line-break: anywhere;
}

.google-visualization-tooltip {
  pointer-events: none !important;
}

.hide {
  display: none !important;
}

.TeacherLoginBtn {
  background: #ffffff !important;
  color: #024863 !important;
  text-transform: initial !important;
  font-weight: 900 !important;
  font-size: 1rem !important;
  border-radius: 0.5rem !important;
  border: 1px solid #9fd0e3 !important;
  width: 100% !important;
}

.TeacherLoginBtn:hover {
  background-color: #b3d9ff !important;
  border-radius: 5px !important;
}
.active {
  background-color: #b3d9ff !important;
  border-radius: 5px !important;
}

.MuiFormLabel-root {
  font-family: "Raleway" !important;
}

.searchButton > .MuiTextField-root > .MuiInputBase-root {
  height: 2.5rem;
}
.searchButton > .MuiTextField-root {
  width: 100%;
}

.searchButton {
  margin: 0.5rem 0rem;
}
