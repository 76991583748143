a {
  text-decoration: none;
}
.loginBg {
  /* background-image: url(./images/kneo-bg.png); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  filter: blur(0.3rem);
  z-index: 1040;
  width: 100vw;
  height: 100vh;
}
.logoTeacher {
  background-image: url(../../assests/images/teacher-bg.png);
}

.logoStudent {
  background-image: url(../../assests/images/kneo-bg.png);
}
.loginDialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 33rem; /* Adjust the width as needed */
  background-color: #fff; /* Set the background color */
  padding: 30px 5% 30px 4%;
  border-radius: 25px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}
/* .switchButton {
  margin-top: 10px;
  margin-left: 4%;
} */

.loginLeft {
  float: left;
  margin-left: 3%;
}
.loginRight {
  float: right;
  margin-top: 9px;
}
.GoogleButton {
  display: flex;
  justify-content: space-evenly;
}
.loginLogo {
  width: 100%;
}
button {
  padding: 0 !important;
  border: 0;
  border-radius: 100px;
  background-color: #2ba8fb;
  color: #ffffff;
  /* //font-weight: Bold; */
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  margin-right: 0.3rem !important;
  min-height: 2.5rem;
}

button:hover {
  background-color: #6fc5ff;
  box-shadow: 0 0 20px #6fc5ff50;
  transform: scale(1.03);
}

button:active {
  background-color: #3d94cf;
  transition: all 0.25s;
  -webkit-transition: all 0.25s;
  box-shadow: none;
  transform: scale(0.98);
}

/* .switch {
  position: relative;
  display: inline-block;
  width: 120px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0071bc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
} */

/* .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #f7f2f2;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #22b6ba;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(85px);
} */

/*------ ADDED CSS ---------*/
/* .slider:after {
  content: "STUDENT";
  color: white;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 10px;
}

input:checked + .slider:after {
  content: "TEACHER";
} */

/*--------- END --------*/

.loginOptions {
  display: flex;
  justify-content: space-between;
}
/* .login-switch {
  display: flex;
  justify-content: end;
  padding-top: 1rem;
} */

/*-------gOOGLE---------*/

.googleBtn {
  /* width: 184px;
  height: 42px; */
  /* width: 12rem; */
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 1px 1px 5px 3px rgba(0, 0, 4, 0.25);
  .googleIconWrapper {
    position: absolute;
    margin-top: 1px;
    /* margin-left: 1px; */
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background-color: #fff;
  }
  .googleIcon {
    position: absolute;
    margin-top: 11px;
    margin-left: 11px;
    width: 18px;
    height: 18px;
  }
  .btnText {
    float: right;
    margin: 11px 11px 0 0;
    /* color: #fff; */
    font-size: 14px;
    letter-spacing: 0.2px;
  }
  &:hover {
    box-shadow: 0 0 6px #4285f4;
  }
  &:active {
    /* background: #1669f2; */
  }
}

/* @import url(https://fonts.googleapis.com/css?family=Roboto:500); */

/*-------gOOGLE---------*/

.loginTitle {
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  /* //font-weight: bold; */
  /* text-decoration: underline; */
  color: #333333;
}
.contactDialog {
  /* border-top-style: dashed;
  border-top-color: #808080;
  margin-top: 1rem; */
  display: flex;
  justify-content: center;
  padding: 1rem;
}
/* .contact-link {
  font-size: larger;
  background-color: lightgray;
  padding: 0.7rem;
  border-radius: 2rem;
  color: black;
} */
.loginBtn {
  width: 12rem;
  border-radius: 0.5rem !important;
  background-color: #f7821b !important;
  color: #024863 !important;
  text-transform: initial !important;
  font-weight: 900 !important;
  font-size: 1rem !important;
  border-radius: 0.5rem !important;
  border: 1px solid #9fd0e3 !important;
  /* background: linear-gradient(to right, #22b6ba 0%, #0071bc 100%) !important; */
  /*margin-bottom: 2rem !important;*/
}

.TeacherLoginBtn {
  width: fit-content;
  height: 2rem;
  /* height:min-content;*/
  border-radius: 5px !important;
  /* background: linear-gradient(to right, #ef77c1 0%, #97047e 100%) !important; */
  margin-bottom: 2rem !important;
  text-align: center;
  background: #ffffff !important;
  color: #007bff !important;
  text-transform: initial !important;
  font-weight: 900 !important;
  font-size: 1rem !important;
}
.TeacherLoginButton {
  display: flex;
  justify-content: center;
  width: 50%;
}
.LoginButton {
  display: flex;
  justify-content: center;
  margin-bottom: 0.656rem;
  margin-top: 0.5rem;
}
.contactTitle {
  font-size: smaller;
}
.signIn {
  margin: 0;
}
.loginSpin {
  margin-right: 0% !important;
}
.lh1 {
  line-height: 1.2 !important;
}
.buildVersion {
  text-align: center;
  font-size: smaller;
}
.changePasswordMain {
  margin: 2rem;
}
.resetMsg {
  text-align: center;
  font-size: 1.2rem;
  color: red;
}

.resetBtn {
  width: fit-content;
  margin-left: 7.5rem !important;
}
.iconAlign {
  vertical-align: text-bottom;
}
.textFeildWidth .MuiFormControl-root {
  width: 50%;
}
.loginDisplay
  > .MuiFormControl-root
  > .MuiFormLabel-root
  > .MuiFormLabel-asterisk {
  color: #ff0000;
  font-size: 1.2rem;
  vertical-align: middle;
  font-weight: bold;
}
.loginDisplay div.MuiInputBase-root {
  background-color: aliceblue;
}

@media screen and (max-width: 1206px) {
  .loginDialog {
    width: 25rem;
  }
}

@media screen and (max-width: 750px) {
  .loginDialog {
    width: 20rem;
  }
  .loginOptions {
    display: block;
  }
  .loginLeft {
    float: none;
  }
  .loginRight {
    float: none;
    text-align: center;
  }
}

@media screen and (max-width: 450px) {
  .loginDialog {
    width: 15rem;
  }
  .loginBtnResponsive {
    line-height: 1.15 !important;
  }
}
