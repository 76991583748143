.navMain {
  background-color: #2a3f54;
  min-width: 15rem;
  height: calc(100vh - 70px);
  z-index: 10;
  background-image: url(../../assests/images/kneo-bg2.png);
  overflow: auto;
  background-size: cover;
}
.navList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.navItem {
  display: flex;
  align-items: center;
  height: 3rem;
  width: 100%;
  padding: 0 0 0 1rem;
  text-decoration: none;
}
.navList a {
  text-decoration: none;
  text-transform: capitalize;
  /* //font-weight: 500; */
  font-size: 13px;
  letter-spacing: 1px;
}
.navList li {
  color: #fff;
  font-size: 1.1rem;
  /* //font-weight: bold; */
}
.navList a.active li {
  background-color: rgba(145, 158, 171, 0.08);
  color: #fff;
}
/* .navList li.active {
    background-color: rgba(145, 158, 171, 0.08);
    color: #212B36;
} */
.navItem:hover {
  background-color: rgba(145, 158, 171, 0.08);
  color: #fff;
}
.navItem .MuiSvgIcon-root {
  position: absolute;
  right: 10px;
}
.navSubItem {
  padding-left: 3.5rem;
}
.invertedIcon {
  display: inline-block;
}

.invertedIcon svg {
  /* filter: invert(1); */
  padding: 1rem;
  color:#fff;
}

.navRow {
  display: flex;
  padding: 0.25rem;
}

.navMain::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}
