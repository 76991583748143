.tHeading {
  font-size: large;
}

.legendHeading {
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
}

.legendYellow {
  background-color: #ece817;
  width: 50%;
  border: 1px solid;
}

.legendPurple {
  background-color: #eba7e5db;
  width: 14%;
  border: 1px solid;
}
.legendDarkPurple {
  border: 1px solid black;
  color: white;
  padding: 3px;
  background-color: rgb(122 68 161);
}

.legendGreen {
  background-color: rgba(87, 178, 87, 0.71);
  width: 14%;
  border: 1px solid;
}

.legendOrange {
  background-color: rgb(240, 191, 135);
  width: 14%;
  border: 1px solid;
}

.legendWhite {
  width: 8%;
  background-color: white;
  border: 1px solid;
}

.centerAlign {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 1951px) {
  .displayF {
    display: flex;
  }
  .assessmentContainer {
    width: 50%;
  }
}
@media screen and (max-width: 1950px) {
  .displayF {
    display: block;
  }
  .assessmentContainer {
    width: 100%;
  }
}

.colorWhite {
  color: white;
}

.cardAlternate {
  box-shadow: none !important;
  height: fit-content !important;
}

.lrn p,
.lrn ul {
  width: 90%;
}

.slides-control:after {
  position: static !important;
}
