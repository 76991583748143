header {
  background-color: #0071bc;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  width: -webkit-fill-available;
  z-index: 100;
  position: relative;
}

.headerLogo {
  font-size: 24px;
  /* //font-weight: bold; */
  width: 100%;
  height: auto;
  display:flex;
}

.logoImage {
  float: left;
  width: 20rem;
}

.user-icon {
  cursor: pointer;
  font-size: 18px;
  position: relative;
  padding-right: 2rem;
  display:block;
  z-index: 101;
}

.popup {
  position: absolute;
  top: 4rem;
  right: 1rem;
  background-color: #ffffff;
  padding: 1rem;
  width: 17rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-size: 0.7rem;
  z-index: 102;
  color: black;
  background-color: #f5f5f5;
}

.popupDetails {
  display: flex;
  color: black !important;
}

.popup button {
  background-color: #22b6ba;
  color: #ffffff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.popup button:hover {
  background-color: #1b999d;
}

.resetLink {
  display: flex;
  justify-content: center;
  font-size: larger;
}

.btnLogout {
  display: flex;
  justify-content: center;
}
.btnLightBlue{
  background-color: #22b6ba !important;
  height: 3rem;
}

.WelcomeMsg{
    text-align: center;
    word-wrap: break-word;
    right: 5rem;
    position: absolute;
}


.changePasswordBtn {
  margin-top: 0!important;
  padding: 0px 6px!important;
  font-size: 1rem;
  /* height: 2rem; */
}

/* .css-78trlr-MuiButtonBase-root-MuiIconButton-root  {
  padding: 0!important;
}

.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
  padding: 0!important;
} */