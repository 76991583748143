.LessonHead{
    font-size: 1.5rem;
    
}
.lessonMain{
    display:flex;
}
.lessonLogo {
    width: 12rem;
}

.description {
    margin: 0rem 0 2rem 2rem;
}
.noLessons{
  display: flex;
  justify-content: center;
  font-size: x-large;
  font-weight: bold;

}    


.greenStartButton {
  background-color: #28a745;
  padding: 1.2rem;
}

.yellowStartButton {
  background-color: #f8a84b;
  padding: 1.2rem;
}

.restartButton {
  background-color: #0071bc;
  padding: 1.2rem;
}

.MuiTableCell-head:contains("Select") {
  /* Your custom styling for the header with the "Select" text */
  /* For example, to set the background color and font color */
  background-color: yellow;
  color: red;
}

.episode {
  overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
 
.episode .slide {
  border: 1px solid #2b2b2b;
  display: none;
  height: 384px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 683px;
}
 
.episode .slide .background {
  width: 100%;
}
 
.episode .component,
.episode .fixed-component {
  position: absolute;
}
 
.episode .component.text {
  display: none;
}
 
.episode .bubble {
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
}
 
.orientation-warning {
  display: none;
}
 
.episode-scale {
  height: 384px;
  left: 50%;
  margin-left: -341.5px;
  position: absolute;
  top: 0;
  transform-origin: center top;
  transition: visibility 0.5s;
  visibility: hidden;
  width: 683px;
}
 
.episode-menu {
  top: 334px;
  color: #ffffff;
  left: 10px;
  position: absolute;
}
 
.episode-menu .btn-episode-menu {
  background-color: #0071bc;
  border: 2px solid #ffffff;
  opacity: 0.3;
  padding: 5px 10px;
  transition: opacity 0.5s;
  border-radius: 0.25rem;
}
 
.episode-menu .btn-episode-menu:active, .episode-menu .btn-episode-menu:focus, .episode-menu .btn-episode-menu:hover {
  opacity: 1;
}
 
.episode-menu.show .btn-episode-menu {
  color: #0071bc;
  background-color: #ffffff;
}
 
.episode-menu .dropdown-menu {
  padding: 0;
}
 
.episode-menu .dropdown-menu a {
  color: #0071bc;
  cursor: pointer;
  display: block;
  padding: 8px 10px;
  white-space: nowrap;
}
 
.episode-menu .dropdown-menu a:active, .episode-menu .dropdown-menu a:focus, .episode-menu .dropdown-menu a:hover {
  background-color: #0071bc;
  color: #ffffff;
  text-decoration: underline;
}
 
.episode-controls {
  height: 0;
}
 
.episode-controls .btn-episode-control {
  border-bottom-right-radius: 70px;
  border-top-right-radius: 70px;
  cursor: pointer;
  opacity: 0.3;
  overflow: hidden;
  position: absolute;
  top: 152px;
  transition: opacity 0.5s;
  z-index: 1000;
}
 
.episode-controls .btn-episode-control.hidden {
  height: 0;
}
 
.episode-controls .btn-episode-control:active, .episode-controls .btn-episode-control:focus, .episode-controls .btn-episode-control:hover {
  opacity: 1;
}
 
.episode-controls .btn-episode-control img {
  height: 80px;
} 

.episode-controls .episode-prev {
  left: 10px;
  transform: scale(-1, 1);
}
 
.episode-controls .episode-next {
  right: 10px;
}
 
.episode-controls .mute-icon {
  color: #ffffff;
  cursor: pointer;
  font-size: 1.5em;
  display: none;
  position: absolute;
  top: 346px;
  right: 7px;
  z-index: 1000;
}
 
.episode-controls .mute-icon.muted {
  display: block;
}
 
.bubble {
  padding: 10px 15px 15px 10px;
  width: 300px;
  line-height: 1.2;
}
 
.bubble.transparent {
  color: #ffffff;
}

.bubble:not(.transparent) {
  background-color: #ffffff;
  border-radius: 10px;
}

.bubble::after {
  border-bottom: 15px solid #ffffff;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  content: '';
  display: block;
  position: absolute;
}

.bubble.tail-none::after, .bubble.transparent::after {
  display: none;
}

.bubble.tail-left-top::after {
  left: -7px;
  top: -7px;
  transform: rotate(-45deg);
}

.bubble.tail-middle-top::after {
  left: 50%;
  top: -14px;
  transform: translateX(-50%);
}

.bubble.tail-right-top::after {
  right: -7px;
  top: -7px;
  transform: rotate(45deg);
}

.bubble.tail-left-middle::after {
  left: -14px;
  top: 50%;
  transform: rotate(-90deg) translateX(45%);
}

.bubble.tail-right-middle::after {
  right: -14px;
  top: 50%;
  transform: rotate(90deg) translateX(-45%);
}

.bubble.tail-left-bottom::after {
  bottom: -7px;
  left: -7px;
  transform: rotate(-135deg);
}

.bubble.tail-middle-bottom::after {
  bottom: -14px;
  left: 50%;
  transform: rotate(180deg) translateX(50%);
}

.bubble.tail-right-bottom::after {
  bottom: -7px;
  right: -7px;
  transform: rotate(135deg);
}

.bubble.text-size-small {
  font-size: .8rem;
}

.bubble.text-size-medium {
  font-size: 1rem;
}

.bubble.text-size-large {
  font-size: 1.2rem;
}

.bubble.text-width-small {
  width: 200px;
}

.bubble.text-width-medium {
  width: 300px;
}

.bubble.text-width-large {
  width: 400px;
}

.bubble.flip,
.asset.flip {
  transform: scaleX(-1);
  transform-origin: center center;
}

.component {
  transform-origin: top left;
}

#btn-start {
  background-color: #338dc9;
  border-radius: 3px;
  border: 2px solid #ffffff;
  color: #ffffff;
  cursor: pointer;
  font-weight: bold;
  left: 50%;
  padding: 5px 20px;
  position: absolute;
  top: 334px;
  transform: translateX(-50%);
}

@media (orientation: portrait) {
  .episode,
.episode-controls {
    display: none;
  }
  .orientation-warning {
    display: block;
    width: 100%;
  }
}













.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}